import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
            <h1>The Scandalous Crier</h1>

<h2>Hero or Villain?</h2>

<p>
What’s this? Our sweet and innocent Hero isn’t so sweet and innocent after all. Still love at first sight, Claudio?  
</p>
            </div>
        </Layout>
    )
}